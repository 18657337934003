import React, { useState, useEffect, useContext, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import ThemeContext from '../context/ThemeContext';
import useModalState from '../helpers/modalState';
import prepareSchemaHours from "../helpers/prepareSchemaHours";
import 'react-image-lightbox/style.css'; 

import * as styleSheet from "./index.module.scss"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import loadable from '@loadable/component';

const IndexPage = () => {
	const SmileSlider = loadable(() => import('../components/smileSlider'));
	const Lightbox = loadable(() => import('react-image-lightbox'));
	const CountUp = loadable(() => import('react-countup'));
	const MyLocation = loadable(() => import('../components/myLocation'));
	const ReviewRotator = loadable(() => import('../components/reviewRotator'));

  const [ nearestTenLocations, setNearestTenLocations ] = useState([]);
  const [showAppointmentModal, setShowAppointmentModal] = useModalState();
	const [haveSmileNumbersGenerated, setHaveSmileNumbersGenerated] = useState(false);
	const [ smileNumbers, setSmileNumbers ] = useState([]);
	const [currentLightboxImageIndex, setcurrentLightboxImageIndex] = useState();
	const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
	const [currentLightboxText, setCurrentLightboxText] = useState('');
	const [currentLightboxLocation, setCurrentLightboxLocation] = useState('');
	const [lightboxSmileImages, setLightboxSmileImages] = useState([]);
	const [schemaData, setSchemaData] = useState(null);

	const [ windowSize, setWindowSize] = useState({
		width: 0,
		height: 0
	});

  function showModal(){
		if( !showAppointmentModal.modalOpen ){
			setShowAppointmentModal({modalOpen: true});
		}
	}

	function handleApptKeyDown(e) {
		if (e.keyCode === 13 || e.keyCode === 32) {
			setShowAppointmentModal({modalOpen: true});
		}
	}

  const countUpContainerRef = useRef();
	const [countUpStart, setCountUpStart] = useState(1);

	useEffect(() => {

		countUpContainerRef.current.addEventListener("sal:in", () => {setCountUpStart(0)}, false)

		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
  }, [setWindowSize]); // Empty array ensures that effect is only run on mount

	const data = useStaticQuery(graphql`query IndexPageQuery {
		home: wpPage(isFrontPage: {eq: true}) {
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			ACFHomePage {
				hero {
					images {
						mobileBackgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 699, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageTablet: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 999, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageDesktop: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
								}
								publicURL
								extension
							}
						}
						backgroundImageXL: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
								}
								publicURL
								extension
							}
						}
					}
					content {
						headingImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						headingImageMobile {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 450, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						heading
						blurb
						requestAppointmentLinkText
						phoneNumber
					}
					callouts {
						callout1 {
							preHeading
							heading
							blurb
							makeAnAppointmentLinkText
							backgroundImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
									}
									publicURL
									extension
								}
							}
						}
						callout2 {
							heading
							imAPrefix
							links {
								link {
									target
									title
									url
								}
							}
						}
					}
				}
				intro {
					left {
						blurb
						heading
						fieldGroupName
						list {
							heading
							shortParagraph
							link {
								target
								title
								url
							}
						}
					}
					right {
						smilesPageLink {
							target
							title
							url
						}
					}
				}
				featuredServices {
					leftImage {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData( quality: 100)
								}
								publicURL
								extension
							}
						}
					}
					rightServices {
						featuredServicesSectionTitle
						featuredServices {
							heading
							makeHeadingALink {
								title
								target
								url
							}
							blurb
						}
					}
				}
				seeYourSmileSection {
					left {
						qrCodeImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 140,layout: FIXED)
								}
								publicURL
								extension
							}
						}
						heading
						steps {
							step
						}
						link {
							target
							title
							url
						}
						mobileInstructions
						mobileLink {
							target
							title
							url
						}
					}
					right {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 700)
								}
								publicURL
								extension
							}
						}
					}
				}
				insuranceSection {
					leftText {
						title
						blurb
					}
					rightInsuranceLogos {
						insurances {
							... on WpInsurance {
								id
								title
								ACFInsurance {
									logo {
										altText
										id
										localFile {
											childImageSharp {
												gatsbyImageData(width: 90, layout: CONSTRAINED)
											}
											publicURL
											extension
										}
									}
								}
							}
						}
					}
				}
				smileStats {
					left {
						backgroundImageMobile: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 699, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageTablet: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 999, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageDesktop: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 595, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageXL: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 850, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						heading
						paragraphBlurb
						link {
							target
							title
							url
						}
					}
					right {
						stat {
							number {
								prefix
								number
								suffix
								tagline 
								description
							}
						}
					}
				}
				blogAndSocial {
					blog {
						heading
						blurb
				selectedBlogPost {
					... on WpPost {
					date(formatString: "MMMM D, Y")
					id
					excerpt
					title
					link
					}
				}
						social {
							heading
							blurb
							fbLink
							igLink
						}
					}
				}
				reviews {
					right {
						heading
						selectedReviews {
							... on WpReview {
								id
								ACFReview {
									blurb
									location
									stars
								}
								title
							}
						}
					}
					backgroundImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData
							}
							publicURL
							extension
						}
					}
				}
			}
		}
		site: site {
			siteMetadata {
				title
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
								}
								publicURL
								extension
							}
						}
						mobileNavigationLogo {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
								}
								publicURL
								extension
							}
						}
					}
				}
			}
		}
		rightArrowYellow: file(name: {eq: "arrow"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		rightArrowGreenOffsite: file(name: {eq: "arrow_green_offsite"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		rightArrowGreen: file(name: {eq: "arrow_green"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
				gatsbyImageData(width: 10, layout: FIXED)
			}
		}
		rightArrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
			childImageSharp {
				gatsbyImageData(width: 10, layout: FIXED)
			}
		}
		arrowGrayLocal: file(name: {eq: "arrow_gray_local"}) {
			childImageSharp {
				gatsbyImageData(width: 10, layout: FIXED)
			}
		}
		locationIcon: file(name: {eq: "location_icon"}) {
			childImageSharp {
				gatsbyImageData(width: 14, layout: FIXED)
			}
		}
		arrowBlue: file(name: {eq: "arrow-right-blue"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		redStar: file(name: {eq: "Star"}) {
			childImageSharp {
				gatsbyImageData(width: 20, layout: FIXED)
			}
		}
		quotes: file(name: {eq: "quotation_icon"}) {
			childImageSharp {
				gatsbyImageData(width: 70, layout: CONSTRAINED)
			}
		}
		fbIconWhite: file(name: {eq: "facebook-white"}) {
			childImageSharp {
				gatsbyImageData(width: 20, layout: FIXED)
			}
		}
		igIconWhite: file(name: {eq: "instagram-white"}) {
			childImageSharp {
				gatsbyImageData(width: 20, layout: FIXED)
			}
		}
		locations: allWpLocation(sort: {fields: title, order: ASC}) {
			nodes {
				id
				title
				link
				birdeyeLocation {
					businessId
					timezone
					location {
						address1
						address2
						city
						state
						zip
						lat
						lng
					}
					name
					phone
					hoursOfOperations {
						day
						isOpen
						workingHours {
							startHour
							endHour
						}
					}
				}
				WpOrthodontists {
					ACFDentist {
						briefDescription
						fullLengthImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
					}
					title
					link
					ACFDentist {
						headshot {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 280, layout: CONSTRAINED)
								}
							}
						}
					}
				}
				ACFLocation {
					bookMyAppointmentLinkText
					menuTitle
					briefDescription
					locationInfo {
						left {
							dentists {
								... on WpOrthodontist {
									id
									ACFDentist {
										briefDescription
										fullLengthImage {
											altText
											localFile {
												childImageSharp {
													gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
												}
												publicURL
												extension
											}
										}
									}
									title
									link
									ACFDentist {
										headshot {
											localFile {
												childImageSharp {
													gatsbyImageData(width: 280, layout: CONSTRAINED)
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}`);

	const {
		home,
		home: {
			ACFHomePage: {
				hero: {
					images: {
						mobileBackgroundImage: heroBGMobile,
						backgroundImageTablet: heroBGTablet,
						backgroundImageDesktop: heroBGDesktop,
						backgroundImageXL: heroBGXL,
					},
					content: {
						headingImage,
						headingImageMobile,
						heading: heroHeading,
						blurb,
						requestAppointmentLinkText,
						phoneNumber
					},
					callouts: {
						callout1: {
							preHeading: c1preHeading,
							heading: c1heading,
							blurb: c1blurb,
							makeAnAppointmentLinkText,
							backgroundImage: c1backgroundImage
						},
						callout2: {
							heading: c2heading,
							imAPrefix: c2imAPrefix,
							links: c2links,
						}
					}
				},
        featuredServices: {
          leftImage: {
            image: servicesImage
          },
          rightServices: {
            featuredServicesSectionTitle,
            featuredServices
          }
        },
        seeYourSmileSection: {
          left: {
            qrCodeImage,
            heading: seeSmileHeading,
            steps: seeSmileSteps,
            link: seeSmileGalleryLink,
            mobileInstructions: seeSmileMobileText,
            mobileLink: seeSmilesMobileLink
          },
          right: {
            image: seeSmilePhoneScreenshot
          }
        },
				intro: {
					left: {
						blurb: introLeftBlurb,
						heading: introLeftHeading,
						list: introLeftList
					},
          right: {
            smilesPageLink
          }
				},
        insuranceSection: {
          leftText: {
            title: insurancesTitle,
            blurb: insurancesBlurb,
          },
          rightInsuranceLogos: {
            insurances
          }
        },
        smileStats: {
					left: {
					  backgroundImageMobile: statsBackgroundImageMobile, 
					  backgroundImageTablet: statsBackgroundImageTablet,
					  backgroundImageDesktop: statsBackgroundImageDesktop,
					  backgroundImageXL: statsBackgroundImageXL,
					  heading: statsHeading,
					  paragraphBlurb: statsParagraph,
					  link: statsLink 
				  	},
					right: {
            stat: stats
          }
				},
				blogAndSocial: {
					blog: {
					  heading: blogHeading,
					  blurb: blogBlurb,
					  selectedBlogPost,
            social: {
              heading: socialHeading,
              blurb: socialBlurb,
              fbLink: socialFbLink,
              igLink: socialIgLink
            }
					}
				},
				reviews: {
					right: {
						heading: reviewsHeading,
						selectedReviews
					},
					backgroundImage: reviewsBackgroundImage 
				}
			},
		},
		options: {
			headerSection: {
				ACFHeaderOptions: {
					mainNavigationSettings: {
						headerLogo,
            mobileNavigationLogo
					}
				}
			}
		},
    site: {
      siteMetadata: {
        title
      }
    },
		rightArrowGreen,
		rightArrowGreenOffsite,
		rightArrowYellow,
		rightArrowYellowOffsite,
		rightArrowBlue,
		arrowRedLocal,
		arrowGrayLocal,
		arrowBlue,
		locationIcon,
		redStar,
    fbIconWhite,
    igIconWhite,
		quotes,
		locations: {
			nodes: locationNodes
		},
	} = data;

	const { location, getLocation } = useContext(ThemeContext);
	
	useEffect(() => {
		if (locationNodes.length < 1) {
			return;
		}

		const chenalLocation = locationNodes.find(location => location.title.includes('Chenal'));
		const nonChenalLocations = locationNodes.filter(location => !location.title.includes('Chenal'));

		if (!chenalLocation) {
			return;
		}

		const deparments = nonChenalLocations.map(location => {
			const deptHours = prepareSchemaHours(location.birdeyeLocation.hoursOfOperations);
			return `{
				"@context": "https://schema.org",
				"@type": "Dentist",
				"name": "${location.birdeyeLocation.name}",
				"url": "${location.link}",
				"telephone": "${location.birdeyeLocation.phone}",
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "${location.birdeyeLocation.location.address1} ${location.birdeyeLocation.location.address2}",
					"addressLocality": "${location.birdeyeLocation.location.city}",
					"addressRegion": "${location.birdeyeLocation.location.state}",
					"postalCode": "${location.birdeyeLocation.location.zip}",
					"addressCountry": "US"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": ${location.birdeyeLocation.location.lat},
					"longitude": ${location.birdeyeLocation.location.lng}
				},
				"openingHours": [${deptHours}]
			}`;
		});

		const chenalLocHours = prepareSchemaHours(chenalLocation.birdeyeLocation.hoursOfOperations);
		const newSchema = `{
			"@context": "https://schema.org",
			"@type": "Dentist",
			"name": "${chenalLocation.birdeyeLocation.name}",
			"url": "${chenalLocation.link}",
			"telephone": "${chenalLocation.birdeyeLocation.phone}",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "${chenalLocation.birdeyeLocation.location.address1} ${chenalLocation.birdeyeLocation.location.address2}",
				"addressLocality": "${chenalLocation.birdeyeLocation.location.city}",
				"addressRegion": "${chenalLocation.birdeyeLocation.location.state}",
				"postalCode": "${chenalLocation.birdeyeLocation.location.zip}",
				"addressCountry": "US"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": ${chenalLocation.birdeyeLocation.location.lat},
				"longitude": ${chenalLocation.birdeyeLocation.location.lng}
			},
			"openingHours": [${chenalLocHours}],
			"department": [${deparments}]
		}`;

		setSchemaData(newSchema);
	}, []);

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locationNodes.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});

      const tenClosestLocations = locationList.slice(0, 10);
      setNearestTenLocations(tenClosestLocations);
	
			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}

		getLocation();
		sortByDistance();

	}, [location, locationNodes, getLocation])

	return (
    <Layout hideSmilesSection={true}>
			<Seo post={home} />
			<Helmet>
				{schemaData && <script type="application/ld+json">
					{schemaData}
				</script>}
			</Helmet>
			<section className={styleSheet.hero}>
				<div className={styleSheet.top}>
					<div className={styleSheet.herobgcolor}></div>
					<div className={styleSheet.herobgcolormoremobile}></div>
					{ heroBGMobile && <FluidImage image={heroBGMobile} passedClass={styleSheet.mobilebg} loading="eager" /> }
					{ heroBGTablet && <FluidImage image={heroBGTablet} passedClass={styleSheet.bgtablet} loading="eager" /> }
					{ heroBGDesktop && <FluidImage image={heroBGDesktop} passedClass={styleSheet.bgdesktop} loading="eager" /> }
					{ heroBGXL && <FluidImage image={heroBGXL} passedClass={styleSheet.bgxl} loading="eager" /> }
					<div className={styleSheet.content}>
						{ headerLogo && <FluidImage image={headerLogo} passedClass={styleSheet.headerlogo} loading={"eager"} /> }
						{ mobileNavigationLogo && <FluidImage image={mobileNavigationLogo} passedClass={styleSheet.headerlogomobile} loading={"eager"} /> }

            { title && <h1 className={styleSheet.sronly}>{title}</h1>}
						{ heroHeading && <h2 className={styleSheet.heroheading} dangerouslySetInnerHTML={{__html: heroHeading}}></h2> }
						{ blurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: blurb }}></div> }
						{ requestAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className="bookappointmentbtn" aria-expanded={showAppointmentModal.modalOpen ? true : false}>{requestAppointmentLinkText} <FixedImage image={rightArrowGreen} passedClass="icon" /></button> } 

						{ phoneNumber && <a className={styleSheet.phone} href={`tel:${phoneNumber}`}>{phoneNumber} <GatsbyImage
                              image={rightArrowGreenOffsite.childImageSharp.gatsbyImageData}
                              className={styleSheet.icon}
                              placeholder="none"
                              alt='' 
                              /></a> }
					</div>
				</div>
				<div className={styleSheet.bottom}>
					<div className={styleSheet.callout1}  data-sal={windowSize.width < 700 ? 'none' : 'slide-up'} data-sal-delay={windowSize.width < 700 ? '600' : '1200'} data-sal-easing="ease-in">
						{ c1preHeading && (
							<div className={styleSheet.preheadcontainer}>
								<span className={styleSheet.preheading}>{c1preHeading}</span> 
							</div>
						)}
						{ c1heading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: c1heading}}></div> }
						{ c1blurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: c1blurb}}></div> }

            {makeAnAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{requestAppointmentLinkText}</button>}

						{ c1backgroundImage && <FluidImage image={c1backgroundImage} passedClass={styleSheet.bg} loading={"eager"} /> }
					</div>
					<div className={styleSheet.callout2} data-sal="slide-up" data-sal-delay="800" data-sal-easing="ease-in">
						{ c2heading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: c2heading}}></div> }
            <nav className={styleSheet.patientdirections}>
              { c2imAPrefix && <span className={styleSheet.prefix}>{c2imAPrefix}</span> }
              { c2links && c2links.map( (link, index) => {
                if(link.link.url.includes('schedule-online')) {
                return <button key={`ctalinks-${index}`} onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{link.link.title} <FixedImage image={rightArrowGreen} passedClass={styleSheet.icon} /></button> 
              } else {
                return <WPLink key={`ctalinks-${index}`} link={link.link} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} icon={rightArrowGreen} />;
              }
              })}
            </nav>
					</div>
				</div>
			</section>

			<section className={styleSheet.intro}>
				<div className={styleSheet.left}>
					{ introLeftHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introLeftHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ introLeftBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introLeftBlurb}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introLeftList && (
						<ul className={styleSheet.list}>
							{ introLeftList.map( (listitem, _index) => {
								return (
									<li key={`${listitem.heading}-${_index}`} className={styleSheet.listitem} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
										<FixedImage image={rightArrowBlue} passedClass={styleSheet.icon} />
										{ listitem.heading && <span className={styleSheet.listheading} dangerouslySetInnerHTML={{__html: listitem.heading }}></span> }
										{ listitem.shortParagraph && <p className={styleSheet.paragraph} dangerouslySetInnerHTML={{__html: listitem.shortParagraph}}></p> }
										{ listitem.link && <WPLink link={listitem.link} passedClass={styleSheet.link} icon={rightArrowGreenOffsite} passedIconClass={styleSheet.linkicon} /> }
									</li>
								)
							})}
						</ul>
					)}
				</div>

        <div className={styleSheet.right}>
		    <SmileSlider 
				haveSmileNumbersGenerated={haveSmileNumbersGenerated} 
				setHaveSmileNumbersGenerated={setHaveSmileNumbersGenerated} 
				smileNumbers={smileNumbers} 
				setSmileNumbers={setSmileNumbers} 
				currentLightboxImageIndex={currentLightboxImageIndex} 
				setcurrentLightboxImageIndex={setcurrentLightboxImageIndex}
				lightboxIsOpen={lightboxIsOpen}
				setLightboxIsOpen={setLightboxIsOpen}
				currentLightboxText={currentLightboxText}
				setCurrentLightboxText={setCurrentLightboxText}
				currentLightboxLocation={currentLightboxLocation}
				setCurrentLightboxLocation={setCurrentLightboxLocation}
				lightboxSmileImages={lightboxSmileImages}
				setLightboxSmileImages={setLightboxSmileImages}
				/>
        	{smilesPageLink && <span className={styleSheet.smilesLinkWrap}><WPLink link={smilesPageLink} passedClass={styleSheet.smilesPageLink} icon={rightArrowGreen} passedIconClass={styleSheet.linkicon} /></span>}
			{lightboxIsOpen && (
				<Lightbox 
				mainSrc={lightboxSmileImages[currentLightboxImageIndex]} 
				onCloseRequest={() => setLightboxIsOpen(false)} 
				imageTitle={currentLightboxLocation}
				imageCaption={currentLightboxText}
				enableZoom={false}
				/>
			)}
        </div>
			</section>

      <div className={styleSheet.servicesandsmilesectionwrap}>
        {featuredServices && <section className={styleSheet.featuredservices}>
          <div className={styleSheet.leftimage}>
            <FluidImage image={servicesImage} passedClass={styleSheet.servicesimage} loading="lazy" />
          </div>
          <div className={styleSheet.rightservices}>
            {featuredServicesSectionTitle && <h2 className={styleSheet.ftServicesSectionHeading}>{featuredServicesSectionTitle}</h2>}
            <ul className={styleSheet.serviceslist}>
              {featuredServices.map((ftService, _index) => {
                return (
                  <li key={'ftService-'+_index} className={styleSheet.ftservice}>
                    {ftService.makeHeadingALink && ftService.heading && <h2 className={styleSheet.title}><a href={ftService.makeHeadingALink.url} target={ftService.makeHeadingALink.target}>{ftService.heading}</a></h2>}
                    {!ftService.makeHeadingALink && ftService.heading && <h2 className={styleSheet.title}>{ftService.heading}</h2>}
                    {ftService.blurb && <p className={styleSheet.blurb}>{ftService.blurb}</p>}
                  </li>
                )}
              )}
            </ul>
          </div>
        </section>}


      {/** See your smile */}
      <section className={styleSheet.seeyoursmile}>
        <div className={styleSheet.contentwrap}>
            <div className={styleSheet.left}>
                  {seeSmileHeading && <h2 className={styleSheet.heading}>{seeSmileHeading}</h2>}
                  {seeSmileMobileText ? <p className={styleSheet.mobileinstructions}>{seeSmileMobileText}</p>: 'oh boy'}
                  <div className={styleSheet.stepsandcode}>
                    {seeSmileSteps && <ol className={styleSheet.smilesteps}>
                      {seeSmileSteps.map((step, _index) => {
                        const stepText = step.step
                        return <li key={'step-' + _index}>{stepText}</li>
                      })}
                    </ol>}

                    {qrCodeImage && (
                      <div className={styleSheet.scancode}>
                        <p className={styleSheet.scantext}>Scan the QR Code</p>
                        <FixedImage image={qrCodeImage} alt={qrCodeImage.altText} />
                      </div>
                    )}
                  </div>

                  {seeSmileGalleryLink && <p className={styleSheet.linkwrapdesktop}><WPLink link={seeSmileGalleryLink} passedClass={styleSheet.link} icon={rightArrowGreen} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /></p>}
                  {seeSmilesMobileLink && <p className={`${styleSheet.linkwrapmobileonly} mobile-only`}><WPLink link={seeSmilesMobileLink} passedClass={`${styleSheet.linkmobile} linkmobilesmilesgallery`} /></p>}

            </div>
            <div className={styleSheet.right}>
              <div className={styleSheet.seesmilephoneimagewrap}>
                {seeSmilePhoneScreenshot && <FluidImage image={seeSmilePhoneScreenshot} passedClass={styleSheet.seesmilephoneimage} loading="lazy" />}
              </div>
            </div>
        </div>
      </section>

      </div>

		{ insurances && (
			<section className={styleSheet.insurance} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
          		<div className={styleSheet.left}>
					{insurancesTitle && <h2 className={styleSheet.title}>{insurancesTitle}</h2>}
					{insurancesBlurb && <p>{insurancesBlurb}</p>}
         		</div>
				<div className={styleSheet.right}>
					{ insurances.map( (insurance, _index) => {
					return <FluidImage key={`insurancelogo-${insurance.ACFInsurance.logo.id}`} passedClass={styleSheet.logo} image={insurance.ACFInsurance.logo} />;
							})}
				</div>
			</section> 
		)}

      <section className={styleSheet.statscallout}>
				<div className={styleSheet.left}>
					{ statsBackgroundImageMobile && <FluidImage image={statsBackgroundImageMobile} passedClass={styleSheet.bgmobile} /> }
					{ statsBackgroundImageTablet && <FluidImage image={statsBackgroundImageTablet} passedClass={styleSheet.bgtablet} /> }
					{ statsBackgroundImageDesktop && <FluidImage image={statsBackgroundImageDesktop} passedClass={styleSheet.bgdesktop} /> }
					{ statsBackgroundImageXL && <FluidImage image={statsBackgroundImageXL} passedClass={styleSheet.bgxl} /> }

					{ statsHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: statsHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ statsParagraph && <p dangerouslySetInnerHTML={{__html: statsParagraph}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></p> }

					{ statsLink && !statsLink.url.includes('schedule-online') && <WPLink link={statsLink} passedClass={styleSheet.link} icon={rightArrowYellow} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />}
          { statsLink && statsLink.url.includes('schedule-online') &&  <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{statsLink.title} <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></button>}
				</div>
				<div className={styleSheet.right}>
					{ stats && (
						<div className={styleSheet.statscontainer}>
							{ stats && stats.map((statGroup, _index) => {
								return (
                  <div key={`smilestate-'${_index}`} className={styleSheet.featuredstat} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" ref={_index === 0 ? countUpContainerRef : null}>
                    <p className={styleSheet.numbergroup}>
                      {statGroup.number?.prefix && <span className={styleSheet.numberprefix}>{`${statGroup.number.prefix} `}</span>}
                      <CountUp 
                          start={countUpStart}
                          end={statGroup.number.number}
                          duration={3.75}
                          separator=","
                          decimals={0}
                          decimal=","
                          delay={0}
                        >
                          {({ countUpRef, start }) => (
                              <span className={styleSheet.countupnumber} ref={countUpRef} />
                          )}
                        </CountUp>
                      {statGroup.number?.suffix && <span className={styleSheet.numbersuffix}>{` ${statGroup.number.suffix}`}</span>}
                      {statGroup?.number?.tagline && <span className={styleSheet.title}>{statGroup.number.tagline}</span>}
                    </p>
                    { statGroup?.number?.description && <p>{statGroup.number.description}</p>}
                  </div>
								)
							})}
						</div>
					)}
				</div>
			</section>



			<section className={styleSheet.bloglocations}>
				<div className={styleSheet.wrap}>

					{ blogHeading && <h2 className={styleSheet.heading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">{blogHeading}</h2> }
					{ blogBlurb && <div dangerouslySetInnerHTML={{__html: blogBlurb}} className={styleSheet.blogblurb} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /> }

          {selectedBlogPost && <article className={styleSheet.ftpost} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
              <Link to={selectedBlogPost.link} className={styleSheet.titlelink}>
                <h2 className={styleSheet.title}>{selectedBlogPost.title}</h2>
              </Link>
              <span className={styleSheet.date}><strong>{selectedBlogPost.date}</strong></span>
              <div className={styleSheet.excerpt} dangerouslySetInnerHTML={{__html: selectedBlogPost.excerpt}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />
              <Link to={selectedBlogPost.link} className={styleSheet.readmorelink}>Read more <span className={styleSheet.sronly}>about the article</span><FixedImage image={rightArrowGreen} passedClass={styleSheet.icon} /></Link>
          </article>}

          <div>
            { socialHeading && <h2 className={styleSheet.heading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">{socialHeading}</h2> }
            { socialBlurb && <div className={styleSheet.socialblurb} dangerouslySetInnerHTML={{__html: socialBlurb}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /> }
            {(socialFbLink || socialIgLink) && <div className={styleSheet.socialmedia} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
              {socialFbLink && <a href={socialFbLink} target="_blank" rel="noreferrer" className={styleSheet.fblink}> <FixedImage image={fbIconWhite} alt={'Facebook'} /> <span className={styleSheet.sronly}>Facebook profile</span></a>}
              {socialIgLink && <a href={socialIgLink} target="_blank" rel="noreferrer" className={styleSheet.iglink}> <FixedImage image={igIconWhite} alt={'Instagram'} /><span className={styleSheet.sronly}>Instagram profile</span></a>}
            </div>}
          </div>

				</div>
				<div className={styleSheet.location}>
					<div className={styleSheet.left} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
						<MyLocation locations={locationNodes} arrowRedLocal={arrowRedLocal} arrowGrayLocal={arrowGrayLocal} locationIcon={locationIcon} rightArrowYellowOffsite={rightArrowYellowOffsite} rightArrowGreenOffsite={rightArrowGreenOffsite} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />
					</div>
					<div className={styleSheet.right}>
						<span className={styleSheet.heading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">Other nearby locations.</span>
						<ul className={styleSheet.list} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
							{ nearestTenLocations && nearestTenLocations.map((location, index) => {
								return (
									<li key={`lstloc-'${location.id}`} className={styleSheet.listitem}>
										<Link to={location.link} className={styleSheet.link}>
											<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
											<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></span>
										</Link>	
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</section>
			<section className={styleSheet.reviews}>
				{ reviewsBackgroundImage && <FluidImage image={reviewsBackgroundImage} passedClass={styleSheet.bg} /> }
				<div className={styleSheet.inner}>
					{ reviewsHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: reviewsHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ selectedReviews && <ReviewRotator reviews={selectedReviews} redStar={redStar} quotes={quotes} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /> }
				</div>
			</section>
		</Layout>
    );
};

export default IndexPage;
